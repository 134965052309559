import { Component } from 'react';
import { connect } from 'react-redux';
import ExecutionEnvironment from 'exenv';
import sanitize from 'sanitize-html';

import { cn } from 'helpers/classnames';
import LandingSlot, { SOREL_FORM } from 'containers/LandingSlot';
import LayoutTupac from 'containers/LayoutTupac';
import { evFitSurveyResponseFromUrl, pvHome, pvLanding } from 'events/landing';
import { pageTypeChange } from 'actions/common';
import { createViewHomePageMicrosoftUetEvent, pushMicrosoftUetEvent } from 'actions/microsoftUetTag';
import { firePixelServer } from 'actions/pixelServer';
import { fetchAllRecommenderDataIfNecessary, getIpRestrictedStatus, loadLandingPage, toggleEasyFlowModal } from 'actions/landing/landingPageInfo';
import { EASYFLOW_ENROLLMENT_URL } from 'constants/rewardsInfo';
import { Loader } from 'components/Loader';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import VipPrimeLink from 'components/landing/VipPrimeLink';
import { stripSpecialCharsDashReplace } from 'helpers';
import marketplace from 'cfg/marketplace.json';
import { titaniteView, track } from 'apis/amethyst';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { setupLandingEventWatcher, shouldLazyLoad } from 'helpers/LandingPageUtils';
import { getHeartingStyleIdsForComponent, getHeartProps } from 'helpers/HeartUtils';
import { MartyContext } from 'utils/context';
import { getHeartCounts, getHearts, heartProduct, toggleHeartingLoginModal, unHeartProduct } from 'actions/hearts';
import { checkIsHomepage } from 'history/historyFactory.js';
import { onEvent } from 'helpers/EventHelpers';
import { isZAWPage } from 'helpers/zaw';
import { triggerAssignment } from 'actions/ab';
import { HYDRA_HOMEPAGE_PERSONALIZATION_TEST } from 'constants/hydraTests';
import { setFederatedLoginModalVisibility, setHFSearchTerm, updateOriginalTerm } from 'actions/headerfooter';
import { loadFromLocalStorage } from 'helpers/localStorageUtilities';
import { fireExplicitSearchEventFromLocalStorage } from 'helpers/searchInputUtils';
import { selectIsCustomer } from 'selectors/cookies';
import { selectLocation } from 'selectors/router';
import { getIsGoodsLabelPage } from 'utils/landing';
import { selectLandingPageInfo } from 'selectors/landing';
import { IS_PRINT_PROTOCOL } from 'common/regex';

import css from 'styles/containers/landing.scss';

export const LAYOUT_TUPAC = 'Tupac';

const {
  shortName,
  homepage,
  hasHeartCounts,
  hasHearting,
  features: { showAccountRewards }
} = marketplace;

const HEARTING_AWARE_COMPONENTS = ['genericBrandTrending', 'productSearch', 'recommender'];

export class Landing extends Component {
  static beforeFetchDataOnServer(store, location) {
    const isHomepage = checkIsHomepage(location.pathname);
    if (isHomepage) {
      return store.dispatch(triggerAssignment(HYDRA_HOMEPAGE_PERSONALIZATION_TEST));
    }
  }

  static fetchDataOnServer(store, location, { pageName }) {
    return store.dispatch(loadLandingPage(checkIsHomepage(location.pathname) ? homepage : pageName, location));
  }

  constructor(props) {
    super(props);
    this.onComponentClick = this.onComponentClick.bind(this);
    this.trackPageView = this.trackPageView.bind(this);
    this.makePageHeading = this.makePageHeading.bind(this);
    this.getAllHeartCounts = this.getAllHeartCounts.bind(this);
  }

  componentDidMount() {
    const {
      track,
      location = {},
      landingPage: { pageName: currentPage, pageInfo = {} },
      pageTypeChange,
      pushMicrosoftUetEvent,
      fetchAllRecommenderDataIfNecessary,
      params: { pageName: newPage },
      setupLandingEventWatcher,
      isHomepage,
      getHearts
    } = this.props;

    const { slotData } = pageInfo;

    const { search } = location;
    // Note: When we're on homepage, newPage is undefined
    const isDifferentPage = !currentPage || (newPage && currentPage !== newPage) || (isHomepage && currentPage !== homepage);

    // Set the correct page type
    const pageType = isHomepage ? 'homepage' : 'landing';
    pageTypeChange(pageType);

    if (pageType === 'landing' && loadFromLocalStorage('explicitSearchEvent')) {
      fireExplicitSearchEventFromLocalStorage();
    }

    if (isDifferentPage) {
      this.fetchData(isHomepage ? homepage : newPage);
    } else {
      // Track page view right away if we already have the data.
      // Otherwise wait for data first in willReceiveProps
      this.trackPageView(newPage);
    }

    if (isHomepage) {
      pushMicrosoftUetEvent(createViewHomePageMicrosoftUetEvent());
    }

    fetchAllRecommenderDataIfNecessary(slotData);

    // Get hearting list
    getHearts();

    this.getAllHeartCounts();

    const utmContent = new URLSearchParams(search).get('utm_content');

    if (utmContent && utmContent.includes('postPurchaseSizing')) {
      track(() => [evFitSurveyResponseFromUrl, { search }]);
    }

    setupLandingEventWatcher(this);

    this.getIpRestrictedInfo();
  }

  componentDidUpdate(prevProps) {
    const {
      params: nextPropParams,
      landingPage: nextLandingPage,
      isHomepage,
      recommenderEntries: nextRecommenderEntries,
      triggerAssignment
    } = this.props;
    // if going from one landing page to another, update state
    const {
      params: { pageName: currentPage },
      landingPage: { pageInfo },
      recommenderEntries
    } = prevProps;
    const { pageName: newPage } = nextPropParams;

    if (currentPage !== newPage) {
      if (isHomepage) {
        triggerAssignment(HYDRA_HOMEPAGE_PERSONALIZATION_TEST);
        this.fetchData(homepage);
      } else if (nextLandingPage.pageName !== newPage) {
        // only load this LP if it's not already loaded
        this.fetchData(newPage);
      }
    }

    if (recommenderEntries !== nextRecommenderEntries) {
      this.getAllHeartCounts();
    }

    if (nextLandingPage.pageInfo && pageInfo?.canonicalUrl !== nextLandingPage?.pageInfo?.canonicalUrl) {
      this.trackPageView(newPage, this.props);
      this.getIpRestrictedInfo();
    }

    // if going to a link from a loaded page and there a hash within the url, the content will scroll to the anchor tag.
    if (this.props.landingPage.isLoaded && !prevProps.landingPage.isLoaded) {
      const hash = this.props.location.hash.replace(/^#/, '');
      if (hash) {
        const hashElement = document.getElementById(hash);
        hashElement.scrollIntoView();
      }
    }
  }

  static contextType = MartyContext;

  getIpRestrictedInfo = () => {
    const {
      landingPage: { ipStatus: { callCompleted } = {}, pageInfo: { ipRestrictedContentPresent = false } = {} },
      getIpRestrictedStatus
    } = this.props;
    if (ipRestrictedContentPresent && !callCompleted && ExecutionEnvironment.canUseDOM) {
      getIpRestrictedStatus();
    }
  };

  getAllHeartCounts() {
    const {
      getHeartCounts,
      recommenderEntries,
      landingPage: { pageInfo: { slotData } = {} }
    } = this.props;
    if (!hasHeartCounts) return;
    const allHeartingStyleIds = getHeartingStyleIdsForComponent(slotData, recommenderEntries);
    if (allHeartingStyleIds.length) {
      getHeartCounts(allHeartingStyleIds);
    }
  }

  fetchData(newPage) {
    const { loadLandingPage } = this.props;
    loadLandingPage(newPage, window.location);
  }

  trackPageView(pageName, props = this.props) {
    const {
      firePixelServer,
      trackEvent,
      track,
      isHomepage,
      landingPage: { pageInfo }
    } = props;

    if (isHomepage) {
      trackEvent('TE_PV_HOMEPAGE');
      firePixelServer('home');
    } else {
      trackEvent('TE_PV_LANDINGPAGE');
      firePixelServer('landing', {}, pageName);
    }

    titaniteView();

    const slotData = pageInfo ? pageInfo.slotData : null;
    if (isHomepage && slotData) {
      track(() => [pvHome, { slotData }]);
    } else if (slotData) {
      track(() => [pvLanding, { pageName, slotData }]);
    }
  }

  makePageHeading(isFullWidth) {
    const {
      landingPage: {
        pageInfo: { pageHeading }
      },
      isHomepage
    } = this.props;
    if (isHomepage) {
      return (
        <h1 className={'sr-only'} data-test-id={this.context.testId('heading')}>
          {`${shortName} Homepage`}
        </h1>
      );
    } else if (pageHeading) {
      return (
        <h1
          className={cn(css.heading, { [css.fullWidth]: isFullWidth })}
          data-test-id={this.context.testId('heading')}
          dangerouslySetInnerHTML={{ __html: sanitize(pageHeading) }}
        />
      );
    }
  }

  makeAction = () => {
    const { landingPage, isHomepage } = this.props;
    let action = `Landing-${landingPage.pageInfo.subPageType}-${landingPage.pageName}`;
    // special labels for homepage per analytics instructions
    if (isHomepage) {
      action = `Gateway-${landingPage.pageName}`;
    }
    return action;
  };

  onComponentClick(e) {
    // Send Analytics component click data via trackLegacyEvent(). The format of this data will change in the future
    // but for now we're sending the landing page parameters the way analytics/siteops wants them, mostly
    // the same as how they're currently formatted in legacy
    const { trackLegacyEvent, toggleEasyFlowModal, setHFSearchTerm, landingPage, isCustomer, setFederatedLoginModalVisibility } = this.props;
    const { currentTarget } = e;
    const action = this.makeAction();
    const label = stripSpecialCharsDashReplace(currentTarget.getAttribute('data-eventlabel'));
    const value = stripSpecialCharsDashReplace(currentTarget.getAttribute('data-eventvalue'));

    // if link contains EasyFlow path, display the modal instead
    if (currentTarget.pathname === EASYFLOW_ENROLLMENT_URL) {
      e.preventDefault();
      toggleEasyFlowModal(true);
    }

    if (!isCustomer && getIsGoodsLabelPage(landingPage.pageName) && IS_PRINT_PROTOCOL.test(currentTarget.href)) {
      e.preventDefault();

      const { pathname, search } = this.props.location;
      const returnTo = encodeURIComponent(`${pathname}${search}`);

      setFederatedLoginModalVisibility(true, { returnTo });
    }

    e.stopPropagation();

    trackLegacyEvent(action, label, value);

    setHFSearchTerm('');
  }

  makeLayout = (pageLayout, pageName, pageInfo, slotData, slotOrder, ipStatus, slotContentTypesList, heartsData, isFullWidth, isCustomer) => {
    if (pageLayout === LAYOUT_TUPAC) {
      return (
        <LayoutTupac
          pageName={pageName}
          slotData={slotData}
          slotOrder={slotOrder}
          onComponentClick={this.onComponentClick}
          ipStatus={ipStatus}
          slotContentTypesList={slotContentTypesList}
          heartingAwareComponents={HEARTING_AWARE_COMPONENTS}
          heartsData={heartsData}
          isFullWidth={isFullWidth}
        />
      );
    }

    // purposeful hard coding a form on `sorel-giveaway` page in slot 2 - https://jira.zappos.net/browse/DK-964
    const slotOrderModified = pageName === 'sorel-giveaway' ? [...slotOrder.slice(0, 1), SOREL_FORM, ...slotOrder.slice(1)] : slotOrder;
    const slotDataModified =
      pageName === 'sorel-giveaway'
        ? {
            ...slotData,
            ...{
              [SOREL_FORM]: {
                isCustomer: isCustomer,
                componentName: SOREL_FORM,
                surveyName: 'sorel-giveaway-data',
                formHeading: 'Zappos x SOREL NYC Sweepstakes Entry Form',
                submittedMsg:
                  'Thank you for entering the Zappos x SOREL NYC Sweepstakes. Winner will be notified via email. Read full terms and conditions to learn more.'
              }
            }
          }
        : slotData;

    return slotOrderModified.map((slotName, slotIndex) => (
      <LandingSlot
        key={slotName}
        slotName={slotName}
        slotIndex={slotIndex}
        data={slotDataModified[slotName]}
        pageName={pageName}
        pageInfo={pageInfo}
        onComponentClick={this.onComponentClick}
        slotHeartsData={HEARTING_AWARE_COMPONENTS.includes(slotData[slotName]?.componentName) && heartsData}
        shouldLazyLoad={shouldLazyLoad(slotIndex)}
        ipStatus={ipStatus}
        slotContentTypesList={slotContentTypesList}
        isFullWidth={isFullWidth}
      />
    ));
  };

  render() {
    const {
      landingPage: { isFaqPage, isLoaded, pageInfo, pageName, slotOrder, content },
      isCustomer,
      heartProduct,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct,
      ipStatus,
      slotContentTypesList
    } = this.props;
    if (!isLoaded) {
      return <Loader />;
    }

    const heartProps = {
      hasHearting,
      isCustomer,
      heartProduct,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct
    };
    const heartsData = getHeartProps(heartProps, {
      heartEventName: 'TE_LANDING_PRODUCT_HEART',
      unHeartEventName: 'TE_LANDING_PRODUCT_UNHEART'
    });

    const { slotData, pageLayout, fullWidth, fullBleed } = pageInfo;
    const isFullBleed = fullBleed === 'true' || isZAWPage(pageName);
    const isFullWidth = fullWidth === 'true' && !isFullBleed;
    const thisContainer = this;

    const seoProps = {};
    if (isFaqPage) {
      seoProps.itemScope = true;
      seoProps.itemType = 'https://schema.org/FAQPage';
    }

    return (
      <SiteAwareMetadata loading={!isLoaded}>
        <MartyContext.Consumer>
          {context => (
            <div
              {...seoProps}
              className={cn(css.pageWrap, { [css.fullWidth]: isFullWidth, [css.fullBleed]: isFullBleed })}
              data-test-id={context.testId('landingPage')}
              data-layout={pageLayout} // used for SiteMerch bookmarklets
              data-page-id={pageName}
            >
              {thisContainer.makePageHeading(isFullWidth)}
              {showAccountRewards && <VipPrimeLink isPageModal={true} />}
              {content?.fallback && <p className={css.hidden}>***Using Fallback Landing Page***</p>}
              {this.makeLayout(
                pageLayout,
                pageName,
                pageInfo,
                slotData,
                slotOrder,
                ipStatus,
                slotContentTypesList,
                heartsData,
                isFullWidth,
                isCustomer
              )}
            </div>
          )}
        </MartyContext.Consumer>
      </SiteAwareMetadata>
    );
  }
}

Landing.defaultProps = {
  trackEvent,
  trackLegacyEvent,
  track,
  setupLandingEventWatcher,
  onEvent
};

function mapStateToProps(state, ownProps) {
  return {
    landingPage: state.landingPage,
    ipStatus: state.landingPage.ipStatus,
    isHomepage: checkIsHomepage(ownProps.location.pathname),
    recommenderEntries: state.recos,
    isCustomer: selectIsCustomer(state),
    params: ownProps.match?.params || ownProps.params,
    location: selectLocation(state),
    pageInfo: selectLandingPageInfo(state)
  };
}

export default connect(mapStateToProps, {
  loadLandingPage,
  triggerAssignment,
  getIpRestrictedStatus,
  fetchAllRecommenderDataIfNecessary,
  firePixelServer,
  pageTypeChange,
  pushMicrosoftUetEvent,
  getHeartCounts,
  getHearts,
  heartProduct,
  unHeartProduct,
  toggleEasyFlowModal,
  toggleHeartingLoginModal,
  updateOriginalTerm,
  setHFSearchTerm,
  setFederatedLoginModalVisibility
})(Landing);
